@import "styles/variables.scss";

.root {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  right: 16px;
  bottom: 16px;
  z-index: 3;

  color: var(--color-black);
  background-color: var(--color-white);
  padding: 16px 20px 22px 20px;
  box-shadow: 0 6px 16px -2px rgba(0, 0, 0, 0.16);

  &.z-index {
    z-index: 2;
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-width: 100%;
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 12px 25px 16px 25px;
  }
}

.text {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.43;
  margin-bottom: 19px;

  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: 10px;
  }
}

.button {
  white-space: nowrap;

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}
