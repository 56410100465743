@import "styles/variables.scss";

.root {
  display: flex;
  align-items: center;
  padding-bottom: 35px;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 33px;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-silver-lake);
  }
}

.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: var(--color-black);
}

.info {
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.67;
  color: var(--color-charlie);
  letter-spacing: normal;
}
