.textarea {
  display: block;
  color: var(--color-black);

  width: 100%;
  max-width: 480px;
  min-height: 211px;
  margin-bottom: 15px;
  padding: 16px 21px;

  font-size: 14px;

  border: solid 1px var(--color-hotel);
  border-radius: 0;

  background-color: var(--color-alpha);

  outline: none;

  -webkit-appearance: none;
  resize: vertical;
  resize: none;

  transition: 0.2s border-color;

  &:focus {
    border-color: var(--color-echo);
  }

  &:active,
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--color-delta);
  }
}
