@import "@app/styles/variables.scss";

.root {
  max-width: unset !important; // Добавил потому что на проде всплыл баг с шириной, shared modal почему-то перезаписывает стили (временное решение)  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  z-index: 100;
}

.content {
  width: 100%;
  max-height: 100%;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 40px;
  }
}

.back {
  position: fixed;
  left: 54px;
  top: 27px;

  @media screen and (max-width: $breakpoint-md) {
    left: 0;
    top: 0;
    margin-left: 0;
    padding: 20px;
    padding-top: 21px;
  }
}
