.label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .hint {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}
