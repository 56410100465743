@import "styles/variables.scss";

.text {
  font-size: 14px;
  line-height: 20px;
  max-width: 95%;
  color: var(--color-black);
}

.button {
  margin-top: 25px;
  @media screen and (max-width: $breakpoint-lg) {
    padding: 0;
    background-color: transparent;
  }
}
