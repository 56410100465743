@import "styles/variables.scss";

.form {
  width: 100%;
}

.title {
  font-weight: 300;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-black);
}

.description {
  max-width: 390px;
  margin-top: 17px;

  color: var(--color-charlie);
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.textarea {
  margin-top: 35px;
  margin-bottom: 0;
}

.button {
  margin-top: 30px;
}

.error {
  border-color: var(--color-kilo) !important;
}

.scalable-area {
  width: 114.2% !important;
  min-height: 241.14px !important;

  font-size: 16px !important;

  transform: scale(0.875) !important;
  transform-origin: left !important;

  resize: none !important;
}

.error-message {
  margin-top: 5px;

  color: var(--color-kilo);
  font-weight: 500;
  font-size: 11px;
  letter-spacing: normal;
}
