.radio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.input {
  display: none;

  &:checked {
    & ~ .mark-icon::before {
      background-color: var(--color-bravo);
    }
  }
}

.mark-icon {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 12px;

  border: 1px solid var(--color-hotel);
  border-radius: 50%;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 6px;
    height: 6px;

    content: "";

    background-color: transparent;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}

.text {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;

  color: var(--color-bravo);
}
