.input {
  width: 100%;
  height: 55px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: var(--color-bravo);
  background-color: var(--color-alpha);
  border: solid 1px var(--color-echo);
  outline: none;
  transition: border 0.2s;

  &::placeholder {
    color: var(--color-golf);
  }

  &:hover {
    border-color: var(--color-foxtrot);
  }

  &:focus {
    border-color: var(--color-bravo);
  }

  &.error {
    border-color: var(--color-vineyard-autumn);
  }
}
