@import "styles/variables.scss";

.prev,
.next {
  position: fixed;
  top: 50%;
  width: 50px;
  height: calc(100% - 120px);
  z-index: 20;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.2s linear;

  &:hover {
    height: calc(100% - 250px);
    transform: translateY(-50%) scale(1.2);
  }

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.prev {
  left: 0;
  background-image: url("../../../../public/img/chevron-left-black.svg");
  transform: translateY(-50%);

  body[class="theme-dark"] & {
    background-image: url("../../../../public/img/chevron-left-white.svg");
  }
}

.next {
  right: 0;
  background-image: url("../../../../public/img/chevron-right-black.svg");
  transform: translateY(-50%);

  body[class="theme-dark"] & {
    background-image: url("../../../../public/img/chevron-right-white.svg");
  }
}

.disabled-arrow {
  cursor: auto;
  opacity: 0.3;
}

.swiper-slide {
  scroll-snap-stop: always;
}

.products {
  width: 100%;
  overflow-y: auto;
  padding: 0 34px !important;
  margin-bottom: 68px;
}
