@import "styles/variables";

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-right: 30px;
  margin-left: 30px;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-md) {
    margin-right: 0;
    margin-left: 0;
  }
}

.image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.32s;
}

.ready {
  visibility: visible;
  opacity: 1;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    circle {
      stroke: var(--color-bravo);
    }
  }
}
