@import "styles/variables.scss";

.root {
  position: relative;
  margin-left: -1px;
  width: calc(100% + 1px);

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    margin-top: -1px;
    margin-left: 0;
  }
}

.radio {
  position: absolute;
  z-index: -1;

  width: 0;
  height: 0;

  visibility: hidden;

  opacity: 0;
}

.label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-height: 57px;
  padding: 16px 16px 19px;

  color: var(--color-bravo);
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;

  border: 1px solid var(--color-golf);

  box-shadow: inset 0 0 0 1px transparent;

  cursor: pointer;

  transition: all 0.1s;
  position: relative;

  &:hover {
    z-index: 1;
  }
}

.radio:checked ~ .label {
  border: 1px solid var(--color-bravo);

  box-shadow: inset 0 0 0 1px var(--color-bravo);
  z-index: 2;
}

.loading {
  pointer-events: none;
}
