@import "styles/variables.scss";

.button {
  display: flex;
}

.hiding-action {
  position: fixed;
  top: 7px;
  left: 20px;

  @media screen and (max-width: $breakpoint-md) {
    top: 18px;
    left: 13px;
  }
}

.hidden-icon {
  background-color: var(--color-india);

  svg {
    path {
      stroke: var(--color-bravo);
    }

    path:nth-child(2) {
      fill: var(--color-bravo);
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    svg {
      path {
        stroke: var(--color-bravo);
      }

      path:nth-child(2) {
        fill: none;
      }

      path:nth-child(3) {
        fill: var(--color-bravo);
        stroke: none;
      }
    }
  }
}
