@import "styles/variables.scss";

.root {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
}

.action {
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
    margin-left: 20px;
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-right: 25px;

    &:last-child {
      margin-left: 15px;
    }
  }
}

.button {
  display: flex;
}

.share-button {
  padding-bottom: 1px;
}

.like-button {
  padding-bottom: 2px;
}

.cross {
  align-self: flex-start;
  transition: 0.2s all;

  @media screen and (min-width: $breakpoint-md) {
    &:hover {
      transform: scale(1.2);
    }
  }
}

.liked {
  g {
    fill: var(--color-bravo);
  }
}

.hiding-action {
  position: fixed;
  top: 7px;
  left: 20px;
  @media screen and (max-width: $breakpoint-md) {
    top: 18px;
    left: 13px;
  }
}

.hidden-icon {
  background-color: var(--color-india);
  svg {
    path {
      stroke: var(--color-bravo);
    }
    path:nth-child(2) {
      fill: var(--color-bravo);
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    svg {
      path {
        stroke: var(--color-bravo);
      }
      path:nth-child(2) {
        fill: none;
      }
      path:nth-child(3) {
        fill: var(--color-bravo);
        stroke: none;
      }
    }
  }
}
