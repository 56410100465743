@import "@app/styles/variables.scss";

.root {
  display: flex;
  justify-content: center;
  padding-top: 64px;

  @media (max-width: $breakpoint-md) {
    padding-top: 32px;
  }
}
