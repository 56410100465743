@import "styles/variables.scss";

.hint {
  display: inline-flex;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &::before {
    display: none !important;
  }

  &::after {
    top: 10px !important;
    min-width: 140px !important;
    max-width: 196px !important;
    padding-top: 10px !important;
    padding-right: 12px !important;
    padding-bottom: 10px !important;
    padding-left: 12px !important;
    font-size: 11px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-stretch: normal !important;
    line-height: 1.27 !important;
    color: var(--color-charlie) !important;
    text-align: initial !important;
    text-transform: initial !important;
    letter-spacing: normal !important;
    overflow-wrap: break-word !important;
    white-space: normal !important;
    background-color: var(--color-alpha) !important;
    box-shadow: 0 20px 56px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(0) !important;
  }

  body[class~="theme-dark"] &::after {
    background-color: #242424 !important;
    color: #929292 !important;
  }

  @media screen and (max-width: $breakpoint-md) {
    &[aria-label][data-tooltip-position] {
      &:after {
        top: 22px !important;
        left: 0 !important;
        display: block;
        transform: translateX(-50%) !important;
      }
    }
  }
}
