@import "styles/variables.scss";

.action {
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
    margin-left: 20px;
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-right: 25px;

    &:last-child {
      margin-left: 15px;
    }
  }
}

.button {
  display: flex;
}
