@import "styles/variables.scss";

.root {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;

  min-height: 100%;
}

.title {
  margin-bottom: 25px;

  color: var(--color-bravo);
  font-weight: 300;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.title-secondary {
  margin-bottom: 47px;

  color: var(--color-bravo);
  font-weight: var(--heading-font-weight);
  font-size: 44px;
  font-family: var(--heading-font-family);
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 25px;

    font-size: 20px;
    text-align: left;
  }
}

.description {
  margin-bottom: 25px;

  color: var(--color-charlie);
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
}

.options {
  margin-bottom: 30px;
}

.option {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 30px;
  }
}

.consumers {
  margin-bottom: 34px;
}

.grid {
  display: flex;

  @media screen and (max-width: $breakpoint-md) {
    flex-wrap: wrap;
  }
}

.item {
  display: flex;
  flex-basis: 33.33333333%;
  flex-grow: 1;

  @media screen and (max-width: $breakpoint-md) {
    flex-basis: 100%;
  }
}

.consumer {
  width: 100%;
}

.mark-icon {
  margin-right: 8px;
}

.authentication {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: var(--color-alpha);
}

.authentication-status {
  flex-basis: 100%;

  color: var(--color-bravo);
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}

.loader {
  width: 40px;
  height: 40px;

  opacity: 1;
}

.caption {
  display: flex;
  align-items: center;

  margin-bottom: 15px;
}

.subtitle {
  margin-right: 10px;

  color: var(--color-bravo);
  font-weight: 300;
  font-size: 16px;
}

.subtitle-secondary {
  color: var(--color-bravo);
  font-weight: 300;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
  }
}

.download-consumer-caption {
  margin-bottom: 16px;
}

.modal-wrapper {
  background-color: var(--color-alpha);
}

.modal-inner {
  max-width: 660px;

  @media screen and (max-width: $breakpoint-lg) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

.submit-button {
  margin: 2px 0;
}

.iframe {
  display: flex;
  position: absolute;
  z-index: -1;

  width: 1px;
  height: 1px;

  visibility: hidden;

  opacity: 0;
  border: 1px solid var(--color-alpha);
}

.visible {
  opacity: 1;
  height: 60px;
  width: calc(100% + 4px);
  margin-left: -2px;
  margin-right: -2px;
  z-index: 1;
  position: static;
  visibility: visible;
}

.hidden-button {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

.download-button-weight {
  font-weight: 400;
}
