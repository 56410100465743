.result {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 277px;
}

.result-icon {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
}

.result-text {
  max-width: 225px;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.4;
  color: var(--color-bravo);
  text-align: center;
}

.spinner {
  width: 42px;
  height: 42px;

  svg {
    width: 42px;
    height: 42px;
    stroke-width: 2;
  }
}
