@import "styles/variables.scss";

.empty {
  padding-top: 30px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 23px;
  }
}

.grid {
  padding-top: 64px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 40px;
  }
}

.container {
  @media screen and (max-width: $breakpoint-md) {
    padding-right: 8px;
    padding-left: 8px;
  }
}
