@import "@app/styles/variables.scss";

.root {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.title,
.description {
  text-align: center;
  color: var(--color-black);
}

.title {
  font-size: 44px;
  font-weight: normal;
  letter-spacing: 1px;
  font-weight: var(--heading-font-weight);
  font-family: var(--heading-font-family);
  text-transform: uppercase;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.55px;
  }
}

.description {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  padding-right: 5%;
  padding-left: 5%;
  line-height: 1.5;
  margin-top: 20px;
}

.content {
  padding-top: 40px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 33px;
  }
}
