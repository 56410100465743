@import "styles/variables.scss";

.header {
  padding: 25px 0 0 0;

  @media screen and (max-width: $breakpoint-md) {
    padding: 25px 0 0;
  }
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 37px;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 50px;
  }
}

.back {
  margin-right: 10px;

  @media screen and (max-width: $breakpoint-md) {
    & span {
      display: none;
    }
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.action {
  margin-right: 30px;
  list-style-type: none;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.title {
  margin-bottom: 10px;
  font-family: var(--heading-font-family);
  font-size: 44px;
  font-weight: var(--heading-font-weight);
  line-height: 1.2;
  color: var(--color-bravo);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media screen and (max-width: $breakpoint-md) {
    margin-bottom: 6px;
    font-size: 24px;
    line-height: normal;
    letter-spacing: 0.55px;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: var(--color-charlie);

  @media screen and (max-width: $breakpoint-md) {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    line-height: 1.43;
  }
}

.mark-icon {
  margin-right: 8px;
}

.description {
  max-width: 820px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--color-bravo);
  text-align: center;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    line-height: 1.5;
  }
}

.desktop {
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.mobile {
  display: none;

  @media screen and (max-width: $breakpoint-md) {
    display: block;
    margin-top: 18px;
    margin-right: auto;
    margin-left: auto;
  }
}
