@import "styles/variables.scss";

.root {
  position: relative;

  margin-bottom: 42px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 15px;
  }
}

.bottom-shadow {
  &:after {
    position: absolute;
    bottom: 0;
    content: "";

    width: 100%;
    height: 50px;

    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, var(--color-alpha) 0%, rgba(255, 255, 255, 0) 100%);

    pointer-events: none;
  }
}

.container {
  max-height: 270px;

  overflow-y: auto;

  @media screen and (max-width: $breakpoint-lg) {
    max-height: 200px;
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
}

.checkbox {
  margin-bottom: 16px;
  width: 100%;
}

.separator {
  display: none;

  height: 1px;
  margin-bottom: 33px;

  background-color: var(--color-india);

  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}

.scene-info {
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.67;
  color: var(--color-charlie);
  letter-spacing: normal;

  @media screen and (max-width: $breakpoint-md) {
    flex: 1;
    text-align: right;
    white-space: nowrap;
  }
}
