.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .content {
    padding-top: 40px;
    background-color: var(--color-white);
    margin-top: auto;
    position: relative;
    display: flex;
    flex-direction: column;

    .close-button {
      position: absolute;
      right: 20px;
      top: 20px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
