.player {
  font-family: "Museo Sans Cyrl";
  background-color: transparent;

  :global(.video-react-big-play-button) {
    line-height: 1.95em;
    border: 0;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    border-radius: 60px;
    margin: 0 !important;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 100;
  }

  :global(.video-react-slider) {
    background-color: transparent;
  }

  :global(.video-react-load-progress) {
    background-color: rgba(255, 255, 255, 0.25);

    div {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }

  &:hover {
    :global(.video-react-big-play-button) {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}

.video-controls {
  &:global(.video-react-control-bar) {
    font-family: "Museo Sans Cyrl";
    background-color: rgba(0, 0, 0, 0.75);
  }
}
