@import "styles/variables.scss";

.notification {
  position: fixed;
  left: 50%;
  z-index: 60;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  transform: translateX(-50%);
  bottom: 30px;

  @media screen and (max-width: $breakpoint-md) {
    justify-content: center;
    width: calc(100% - 8px);
    min-width: initial;
    padding: 25px;
    bottom: 4px;
  }
}

.bottom_space {
  bottom: 110px;
}

.enter {
  transform: translateY(30px) translateX(-50%);
  opacity: 0;

  @media screen and (max-width: $breakpoint-md) {
    transform: translateY(100%) translateX(-50%);
  }
}

.enter-done {
  transform: translateY(0) translateX(-50%);
  opacity: 1;
  transition:
    transform 0.2s,
    opacity 0.2s;

  @media screen and (max-width: $breakpoint-md) {
    transform: translateY(0) translateX(-50%);
  }
}

.exit {
  transform: translateY(30px) translateX(-50%);
  opacity: 1;

  @media screen and (max-width: $breakpoint-md) {
    transform: translateY(100%) translateX(-50%);
  }
}

.exit-active {
  opacity: 0;
  transition:
    transform 0.2s,
    opacity 0.2s;
}

.text {
  font-size: 14px;
  font-weight: 300;
  color: var(--common-color-white);

  span {
    opacity: 0.6;
  }

  @media screen and (max-width: $breakpoint-md) {
    text-align: center;
  }
}

.text-center {
  @media screen and (min-width: $breakpoint-md) {
    padding-left: 10px;
  }
}

.link {
  color: var(--common-color-white);
  text-decoration: underline;
  opacity: 1;
}

.image {
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}
