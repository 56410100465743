@import "@app/styles/variables.scss";

.root {
  max-width: unset !important; // Добавил потому что на проде всплыл баг с шириной, shared modal почему-то перезаписывает стили (временное решение)
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
  max-height: 100%;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 40px;
  }
}
