@import "@app/styles/variables.scss";

.root {
  padding-top: 80px;
  padding-left: 32px;
  padding-right: 32px;

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 47px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
