.root {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}

.mark-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: 1px solid var(--color-hotel);

  & svg {
    opacity: 0;
    transition: 0.2s opacity;
    will-change: opacity;
  }
}

.input {
  display: none;

  &:checked ~ .mark-icon {
    & svg {
      opacity: 1;
    }
  }
}

.text {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.43;
  color: var(--color-bravo);
  letter-spacing: normal;
}

.bold {
  font-weight: bold;
}
