@import "styles/variables.scss";

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: var(--color-mike);

  @media screen and (max-width: $breakpoint-md) {
    padding: 25px;
  }
}

.header {
  position: relative;
  z-index: 10;
}

.name,
.name-mobile {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 300;
  color: var(--color-charlie);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.name-mobile {
  @media screen and (min-width: $breakpoint-md) {
    display: none;
  }
}

.root {
  :global(.swiper-container) {
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none;

    @media screen and (max-width: $breakpoint-md) {
      width: auto;
      margin-right: -25px;
      margin-left: -25px;
    }
  }

  :global(.swiper-slide) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
